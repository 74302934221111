import { useEffect } from 'react'
import { getIsDTC, getIsLoggedIn } from '../hooks/useValidUser'
import { ApiClient } from '../newPackages/ApiClient'
import currentUserStore from '../newPackages/StorageProviders/currentUserStore'
import { ItemStorage } from '../newPackages/StorageProviders/localStorageProvider'
import { Config } from '../services/config/Config'
import { configStorage } from '../services/config/ConfigService'
import { Logger } from '../utils/logger'
import { useLocalStorage } from './useLocalStorage'
// Couchrights

export type Couchrights = {
  next_checkin_date: number // Used for limiting checkins from clients
  in_range: boolean // Is the users' current checkin in range
  days_left: number // Number of days before user will be blocked
}

export type CouchrightsWarning = {
  show: boolean
  daysLeft: number
  dismissedAt: number
}

export type CouchrightsState = {
  couchrights: Couchrights | null
  couchrightsWarning: CouchrightsWarning | null
}

export type DismissCouchrightsWarning = (shouldDispatchCouchrightsEvent?: boolean) => void

export const couchrightsStorage = new ItemStorage<Couchrights | null>('couchrights.v2', null)
export const couchrightsWarningStorage = new ItemStorage<CouchrightsWarning | null>('couchrightsWarning.v2', null)

const msAfterDismissToShow = 24 * 60 * 60 * 1000 // 24 hours

const logger = Logger.of('useCouchrights')

export const useCouchrights = () => {
  const [couchrights, setCouchrights] = useLocalStorage<Couchrights | null>(
    couchrightsStorage.key,
    couchrightsStorage.getItem()
  )
  const [couchrightsWarning, setCouchrightsWarning] = useLocalStorage<CouchrightsWarning | null>(
    couchrightsWarningStorage.key,
    couchrightsWarningStorage.getItem()
  )

  // subscribe to couchrights events
  useEffect(() => {
    const unsubscribe = couchrightsSubscribe(({ couchrights, couchrightsWarning }) => {
      setCouchrights(couchrights)
      setCouchrightsWarning(couchrightsWarning)
    })
    checkin()
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    couchrights,
    checkin,
    couchrightsWarning,
    dismissCouchrightsWarning,
  }
}

/**
 * checkin
 * @param currentConfig {Config} - configService.currentConfig (cannot be undefined)
 * @returns Promise<Couchrights | null>
 */
export async function checkin(
  forceCheckin: boolean = false,
  shouldDispatchCouchrightsEvent: boolean = true
): Promise<Couchrights | null> {
  if (typeof window === 'undefined') {
    return null
  }

  const currentConfig = configStorage.getItem()
  if (!currentConfig) {
    return null
  }

  const prev = couchrightsStorage.getItem()
  const user = currentUserStore.getItem()

  // update couchrights StorageItem
  const handleCouchrights = (couchrights: Couchrights | null): Couchrights | null => {
    // -----------------------
    // get + save couchrights
    if (couchrights) {
      couchrights.days_left = Math.max(couchrights.days_left, 0)
    }
    // if days_left === 0, Player error view will be shown automatically
    const shouldReset = couchrights === null || couchrights.days_left <= 0
    if (shouldReset) {
      couchrights = null
    }
    couchrightsStorage.setItem(couchrights)

    // ------------------------------
    // get + save couchrightsWarning
    const couchrightsWarning = warningForCouchrights(couchrights)
    couchrightsWarningStorage.setItem(couchrightsWarning)

    // -----------------------
    // dispatch couchrights event
    if (shouldDispatchCouchrightsEvent) {
      dispatchCouchrightsEvent()
    }

    // -----------------------
    // return
    return couchrights
  }

  // ...
  const isLoggedIn = getIsLoggedIn(user)
  const isDTC = getIsDTC(user)

  if (!isLoggedIn || !isDTC) {
    return handleCouchrights(null)
  }

  const getCouchrights = async (): Promise<Couchrights | null> => {
    const res = await ApiClient.convenientApiFetch({
      method: 'POST',
      url: currentConfig.API.services.couchrights_services.checkin,
    })
    const data = await res.json()

    if (res.ok) {
      const next = data as Couchrights
      return handleCouchrights(next)
    } else {
      const error = data as any
      logger.warn('Error @ checkin', { error })
      return handleCouchrights(null)
    }
  }

  if (forceCheckin || !prev) {
    return await getCouchrights()
  }
  const minCheckinTime = new Date().getTime() + 60 * 60 * 1000
  const nextCheckinTime = new Date(prev.next_checkin_date).getTime()
  // if we're within 60 min of nextCheckinTime, return await callCheckin()
  if (minCheckinTime >= nextCheckinTime) {
    return await getCouchrights()
  } else {
    return prev
  }
}

export function dismissCouchrightsWarning(shouldDispatchCouchrightsEvent: boolean = true) {
  const prevWarning = couchrightsWarningStorage.getItem()

  couchrightsWarningStorage.setItem({
    daysLeft: prevWarning?.daysLeft ?? 0,
    dismissedAt: new Date().getTime(),
    show: false,
  })

  if (shouldDispatchCouchrightsEvent) {
    dispatchCouchrightsEvent()
  }
}

export function couchrightsSubscribe(callback: (data: CouchrightsState) => void): () => void {
  if (typeof window === 'undefined') {
    return () => {}
  }

  const handleEvent: EventListener = (e) => {
    const event = e as CustomEvent<CouchrightsState>
    const data = event.detail
    callback(data)
  }

  window.addEventListener('couchrights', handleEvent)

  return () => {
    window.removeEventListener('couchrights', handleEvent)
  }
}

function dispatchCouchrightsEvent() {
  window.dispatchEvent(
    new CustomEvent<CouchrightsState>('couchrights', {
      detail: {
        couchrights: couchrightsStorage.getItem(),
        couchrightsWarning: couchrightsWarningStorage.getItem(),
      },
    })
  )
}

function warningForCouchrights(couchrights: Couchrights | null): CouchrightsWarning | null {
  couchrights = couchrights || couchrightsStorage.getItem()
  const prevWarning = couchrightsWarningStorage.getItem()
  const currentUser = currentUserStore.getItem()
  if (!currentUser || couchrights === null || couchrights.in_range) {
    return null
  }

  const now = new Date().getTime()
  const dismissedAt = prevWarning?.dismissedAt ?? 0
  const showAt = new Date(dismissedAt).getTime() + msAfterDismissToShow
  const show = now >= showAt
  return {
    daysLeft: couchrights.days_left,
    dismissedAt,
    show,
  }
}
